import "./buyer.css";
import BuyerHome from "./BuyerHome";
// import BuyerProfile from "./Buyerprofile";

export default function Buyer() {
  return (
    <main id="buyer">
      <BuyerHome />
      {/* <BuyerProfile /> */}
    </main>
  );
}
